import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

declare global {
  interface Window {
    squatch: any;
    ire: any;
    impact: any;
    impactToken?: string;
    squatchToken?: string;
    squatchOnReady?: () => void;
  }
}

// @ts-ignore
window.squatchConfig = { domain: process?.env.REACT_APP_DOMAIN };

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
