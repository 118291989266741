import { Layout, Menu } from "antd";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { DownArrow } from "../../assets/icon/DownArrow";
import { Logo } from "../../assets/icon/Logo";
import { Notification } from "../../assets/icon/Notification";
import { Reward } from "../../assets/icon/Reward";
import { Search } from "../../assets/icon/Search";
import { Team } from "../../assets/icon/Team";
import { User } from "../../assets/icon/User";
import { Users } from "../../assets/icon/Users";
import { Avatar } from "../layout/Avatar/Avatar";
import LocaleSelect from "../localeSelect/LocaleSelect";
import "./Navigation.css";
import { useNavigation } from "./useNavigation";

const { Header, Content, Sider } = Layout;

export default function Navigation({
  children,
}: {
  children?: React.ReactNode;
}) {
  const loc = useLocation();
  const { data, states, callbacks } = useNavigation();

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        style={{ position: "fixed", height: "100%" }}
        width="250"
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={states.collapsed}
        onBreakpoint={(broken) => {
          callbacks.setCanBreak(broken);
        }}
        onCollapse={(collapsed, type) => {
          callbacks.setCollapsed(collapsed);
        }}
      >
        <div className="logo-container">
          <Logo onClick={() => callbacks.onNavigate("/app")} />
        </div>

        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[loc.pathname]}
          onSelect={(info) => callbacks.onNavigate(info.key)}
        >
          <div className="drop-down">
            <div className="drop-down-text">
              <p className="drop-down-title">
                <FormattedMessage
                  id="rty56534vbhguiu"
                  defaultMessage="{name}'s Workspace"
                  values={{ name: data?.name }}
                />
              </p>
              <p className="drop-down-sub-title">
                <FormattedMessage
                  id="ghy767klkmnqwe3f56"
                  defaultMessage="1 member"
                />
              </p>
            </div>
            <DownArrow />
          </div>
          <Menu.Item
            icon={
              <Reward
                color={
                  loc.pathname === "/app/klip-rewards" ? "#633eff" : "#656274"
                }
              />
            }
            key="/app/klip-rewards"
          >
            <FormattedMessage
              id="rty4nb7ui89asqQQ"
              defaultMessage="Klip Rewards"
            />
          </Menu.Item>

          <p className="menu-header">
            <FormattedMessage
              id="100oiiutyghvbnvdewd5"
              defaultMessage="Libraries"
            />
          </p>
          <Menu.Item
            icon={
              <User color={loc.pathname === "/app" ? "#633eff" : "#656274"} />
            }
            key="/app"
          >
            <FormattedMessage
              id="gtyu672vcx34@fsebnm89f"
              defaultMessage="Personal"
            />
          </Menu.Item>
          <Menu.Item
            disabled
            icon={
              <Users
                color={loc.pathname === "/app/shared" ? "#633eff" : "#656274"}
              />
            }
            key="shared"
          >
            <FormattedMessage
              id="hjklghjl534252dfrtyu"
              defaultMessage="Shared with me"
            />
          </Menu.Item>
          <Menu.Item
            disabled
            icon={
              <Team
                color={loc.pathname === "/app/team" ? "#633eff" : "#656274"}
              />
            }
            key="team"
          >
            <FormattedMessage id="eerhjgfhbvgf55623sa" defaultMessage="Team" />
          </Menu.Item>

          <div
            style={{
              flexGrow: 1,
              flexShrink: 0,
              minHeight: "30px",
              maxHeight: "250px",
            }}
          />

          <Menu.Item key="/app/plans">
            <FormattedMessage
              id="24frt56gtyn6mklopds34dxza"
              defaultMessage="Plans & Pricing"
            />
          </Menu.Item>
          <Menu.Item disabled key="help">
            <FormattedMessage
              id="5gtyhyyu78vcazsw23wAswqwersda"
              defaultMessage="Help"
            />
          </Menu.Item>
          <Menu.Item key="/logout">
            <FormattedMessage
              id="ghyuugGtrew34deawqfd"
              defaultMessage="Sign Out"
            />
          </Menu.Item>
          <div
            style={{
              flexGrow: 1,
              flexShrink: 0,
              minHeight: "30px",
              maxHeight: "40px",
            }}
          />
          <div style={{ padding: "20px" }}>
            <LocaleSelect />
          </div>
        </Menu>
      </Sider>
      <Layout>
        <Header className="nav-header">
          <div className="nav-header-items">
            <Search />
            <Notification />
            <Avatar dropdown={true} />
          </div>
        </Header>
        <Content className="content-container">{children}</Content>
      </Layout>
    </Layout>
  );
}
