import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuthRequired } from "../../state/useAuthRequired";

export function useMyAccount(props: any) {
  useAuthRequired();

  const { embedWidget, loaded } = props;

  useEffect(() => {
    if (!embedWidget || !loaded) return;
    // Open widget when page is opened
    embedWidget.open();
  }, [embedWidget, loaded]);
}
