import { useAtomValue } from "jotai/utils";
import { LoggedInUser, WidgetIdent } from "../../state/CoreState";
import { useAtom } from "jotai";
import { sendIreEvent } from "../../utils/ireEvent";

export function useVideoCard() {
  const [user] = useAtom(LoggedInUser);
  const widgetIdent = useAtomValue(WidgetIdent);

  function sendEvent() {
    if (!user) return;
    sendIreEvent({
      user,
      event: {
        key: "videoShare",
        fieldName: "shareCount",
        fieldValue: 1,
      },
      actionTrackerId:40226
    });
  }

  return { callbacks: { sendEvent } };
}
