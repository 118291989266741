import { useAtomValue } from "jotai/utils";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { IsLoggedIn } from "./CoreState";

export function useAuthRequired() {
  const isLoggedIn = useAtomValue(IsLoggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);
}
