export const DownArrow = () => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.15179 0.518066H8.31473C8.25782 0.518066 8.20424 0.545968 8.17076 0.591727L5 4.96226L1.82924 0.591727C1.79576 0.545968 1.74219 0.518066 1.68527 0.518066H0.848216C0.775671 0.518066 0.733261 0.600656 0.775671 0.659808L4.71094 6.08503C4.8538 6.28146 5.14621 6.28146 5.28795 6.08503L9.22322 0.659808C9.26674 0.600656 9.22433 0.518066 9.15179 0.518066Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
};
