import { DeleteOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { FormattedMessage } from "react-intl";
import { useAvatar } from "./useAvatar";

type AvatarProps = {
  dropdown: boolean;
};

export const Avatar = ({ dropdown }: AvatarProps) => {
  const { callbacks, data } = useAvatar();

  const clearDataMenu = (
    <Menu>
      <Menu.Item onClick={callbacks.handleMenuClick} icon={<DeleteOutlined />}>
        <FormattedMessage
          id="aertjjatadfgh234234hfgfghd"
          defaultMessage="Clear User Data"
        />
      </Menu.Item>
    </Menu>
  );

  return dropdown ? (
    <Dropdown overlay={clearDataMenu}>
      <div
        className="avatar"
        style={{
          borderRadius: "50%",
          background: "#ffd8bf",
          color: "#fa541c",
          height: "32px",
          width: "32px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "16px",
          fontFamily: "var(--primary-font)",
        }}
      >
        {data.firstInitial}
      </div>
    </Dropdown>
  ) : (
    <div
      style={{
        borderRadius: "50%",
        background: "#ffd8bf",
        color: "#fa541c",
        height: "32px",
        width: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
      }}
    >
      {data.firstInitial}
    </div>
  );
};
