import { atom } from "jotai";
import { LocaleAtom } from "../state/CoreState";
import fr from "./generated/fr.json";
import tr from "./generated/tr.json"

export const MessagesAtom = atom((get) => {
  const locale = get(LocaleAtom);

  if (locale === "fr") return fr;
  if (locale === "tr") return tr;
  return {};
});
