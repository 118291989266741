import { useForm } from "antd/lib/form/Form";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { LoggedInUser, registerAtom, WidgetIdent } from "../../state/CoreState";
import crypto from "crypto"
export function useRegister() {
  const [user, setUser] = useAtom(LoggedInUser);
  let navigate = useNavigate();
  const [form] = useForm();
  const [_, setRegister] = useAtom(registerAtom);
  const [validCode, setValidCode] = useState(false);
  const widgetIdent = useAtomValue(WidgetIdent);
  const onSubmit = async (data: {
    email: string;
    firstName: string;
    lastName: string;
  }) => {
    const shaEmail = crypto
      .createHash("sha1")
      .update(data.email.toLowerCase())
      .digest("hex");
    setUser({
      email: data.email.toLowerCase(),
      firstName: data.firstName,
      lastName: data.lastName,
      shaEmail
    });
    setRegister(true);
  };
  async function upsertUser() {
    if (!user || !widgetIdent) return;
    await window.squatch?.api()?.upsertUser(widgetIdent);
    navigate("/app");
  }

  useEffect(() => {
    upsertUser();
  }, [user, widgetIdent, navigate]);

  const checkCode = (e: any) => {
    setValidCode(e?.target?.value?.length > 5);
  };

  return {
    states: {
      form,
      validCode,
    },
    callbacks: { navigate, onSubmit, checkCode },
  };
}
