import { Button, Form, Input } from "antd";
import "./Forms.css";
import { useLogin } from "../login/useLogin";
import { BackButton } from "../back-button/BackButton";
import { FormattedMessage, useIntl } from "react-intl";
import Footer from "../layout/Footer";

export default function Login() {
  const { callbacks, states } = useLogin();
  const intl = useIntl();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "90vh",
        justifyContent: "space-between",
      }}
    >
      <BackButton />
      <div className="form-outer-container">
        <div className="login-form-container">
          <h1 className="form-header">
            <FormattedMessage
              id="456ty7878srthxfghgsdfd45"
              defaultMessage="Sign in"
            />
          </h1>
          <Form
            layout="vertical"
            form={states.form}
            onFinish={callbacks.onSubmit}
            requiredMark={false}
          >
            <Form.Item
              label={intl.formatMessage({
                id: "jikorthjioet4522345",
                defaultMessage: "Email",
              })}
              name="email"
              required
              rules={[
                {
                  required: true,
                  type: "email",
                  message: intl.formatMessage({
                    id: "la09a8d89asd",
                    defaultMessage: "Email required",
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: "jkhjkrthjisdfgjnk234234",
                defaultMessage: "Password",
              })}
              name="password"
            >
              <Input type="password" />
              <a className="forgot-password-link" href="#">
                <FormattedMessage
                  id="221gagfdg343234dfsfsdfds"
                  defaultMessage="Forgot Password?"
                />
              </a>
            </Form.Item>
            <div className="form-btn-container">
              <Button type="primary" htmlType="submit" block>
                <FormattedMessage
                  id="90897567567gyuhbjkgyuit67"
                  defaultMessage="Sign in"
                />
              </Button>
              <Button onClick={() => callbacks.navigate("/register")} block>
                <FormattedMessage
                  id="0987556ghvyvhfg"
                  defaultMessage="Create an account"
                />
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <Footer
        termsLink="https://example.com/"
        faqLink="https://example.com/"
        supportLink="https://example.com/"
      />
    </div>
  );
}
