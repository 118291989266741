import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { BackButton } from "../back-button/BackButton";
import { Container } from "../layout/Container";
import Footer from "../layout/Footer";

export const ContactSalesSuccess = () => {
  const navigate = useNavigate();
  return (
    <>
      <BackButton />
      <Container height="80vh" alignItems="center" justifyContent="center">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            height: "60%",
            padding: "100px 30px 0px",
          }}
        >
          <h1 className="primary-title" style={{ marginBottom: "50px" }}>
            <FormattedMessage
              id="ertfgasd324gadq24rasd"
              defaultMessage="Thank you!"
            />
          </h1>
          <div style={{ marginBottom: "20px" }}>
            <p className="paragraph-text">
              <FormattedMessage
                id="hdgjhxcvb43tghrubdfg245"
                defaultMessage="We’ve received your submission and our sales team will reach out
              to you soon."
              />
            </p>
            <p className="paragraph-text">
              <FormattedMessage
                id="bfgTRfdah434hgfdfg"
                defaultMessage="In the meantime, check out how different teams are using Klip."
              />
            </p>
          </div>
          <Button type="primary" onClick={() => navigate("/")}>
            <FormattedMessage
              id="ghghyjyjethWQFFHBrfg23434"
              defaultMessage="View Klip use cases"
            />
          </Button>
        </div>
      </Container>
      <Footer
        termsLink="https://example.com/"
        faqLink="https://example.com/"
        supportLink="https://example.com/"
      />
    </>
  );
};
