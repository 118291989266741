import { Button } from "antd";
import { useNavigate } from "react-router";
import { Badge } from "../layout/Badge";
import "./Settings.css";
import { SettingsModal } from "./SettingsModal/SettingsModal";
import { SettingsPlanCard } from "./SettingsPlanCard/SettingsPlanCard";
import { SettingsStatusCard } from "./SettingsStatusCard/SettingsStatusCard";
import { getEndDate, useSettings } from "./useSettingsHook";
import { FormattedMessage, useIntl } from "react-intl";

export const Settings = () => {
  const { callbacks, states, data } = useSettings();
  const navigate = useNavigate();
  const intl = useIntl();

  return states.purchasedBusinessPlan ? (
    <div className="settings-container">
      <h1 className="primary-title">
        <FormattedMessage
          id="dag43643jtfgshfd53"
          defaultMessage="Current Plan"
        />
      </h1>
      <SettingsStatusCard
        planPrice={intl.formatMessage({
          id: "asdfad367kthd4656k",
          defaultMessage: "$50",
        })}
        planText={intl.formatMessage({
          id: "sdfjikojio490560eg",
          defaultMessage: "Business Plan (billed annually)",
        })}
        priceSubText={intl.formatMessage({
          id: "fkhkbu46djkg78e3",
          defaultMessage: "per seat",
        })}
        seatsText={intl.formatMessage({
          id: "fdfjakl264538956sa2",
          defaultMessage: "5 seats",
        })}
        seatsSubText={intl.formatMessage({
          id: "adfhbrjry45548zjsfj",
          defaultMessage: "(1 Admin, 4 Creators)",
        })}
        badgePrefix={intl.formatMessage({
          id: "asdfefghjtuqu45456734572",
          defaultMessage: "Next payment due",
        })}
      >
        <Badge backgroundColor="#e0e0e0" textColor="var(--dark-color)">
          {getEndDate(365, data.locale)}
        </Badge>
      </SettingsStatusCard>
      <Button
        type="primary"
        style={{ marginBottom: "40px" }}
        onClick={() => navigate("/contact-sales")}
      >
        <FormattedMessage
          id="afdsah36534524232"
          defaultMessage="Upgrade to enterprise"
        />
      </Button>
    </div>
  ) : (
    <div className="settings-container">
      <h1 className="primary-title">
        <FormattedMessage
          id="dag43643jtfgshfd53"
          defaultMessage="Current Plan"
        />
      </h1>
      <SettingsStatusCard
        planPrice={intl.formatMessage({
          id: "asdfad367kthd4656k",
          defaultMessage: "$50",
        })}
        planText={intl.formatMessage({
          id: "fsdaklgrsdak356ld",
          defaultMessage: "Business Trial",
        })}
        priceSubText={intl.formatMessage({
          id: "sdfajkl3422sdioio4t",
          defaultMessage: "per month",
        })}
        seatsText={intl.formatMessage({
          id: "sdfhjttet355hhjhj",
          defaultMessage: "1 seat",
        })}
        seatsSubText={intl.formatMessage({
          id: "hjyjyfgnrt33535dfhhddh",
          defaultMessage: "(1 Admin, 0 Creator)",
        })}
        badgePrefix={intl.formatMessage({
          id: "hfghfghrtertert4533453",
          defaultMessage: "Subscription end date",
        })}
      >
        <>
          <Badge backgroundColor="#FDDA8B" textColor="var(--grey-color)">
            {getEndDate(14, data.locale)}
          </Badge>
          <Badge backgroundColor="#FDDA8B" textColor="var(--grey-color)">
            {14}{" "}
            <FormattedMessage
              id="dashhjysrttur53475ghjf"
              defaultMessage="days left in free trial"
            />
          </Badge>
        </>
      </SettingsStatusCard>

      <h1 className="primary-title">
        <FormattedMessage id="dfhjhjkfderwq2rr44" defaultMessage="All Plans" />
      </h1>
      <div className="plan-cards-container">
        <SettingsPlanCard
          planType={"starter"}
          height="850px"
          title={intl.formatMessage({
            id: "fkl6936dnb465lhhs",
            defaultMessage: "Starter",
          })}
          subTitle={intl.formatMessage({
            id: "djgk4756klh876lhujrt",
            defaultMessage: "Quick videos & screenshots",
          })}
          price={intl.formatMessage({
            id: "mutyktydf56433hgrffd",
            defaultMessage: "Free",
          })}
          featuresText={intl.formatMessage({
            id: "dfsjhytdstukghj567543sdfg",
            defaultMessage: "Features",
          })}
          workspaceLimits={[
            <FormattedMessage
              id="ghrtyrytjghjfh35g52"
              defaultMessage="25 videos/user"
            />,
            <FormattedMessage
              id="67589fkgleryvonmw27"
              defaultMessage="5 min recording length"
            />,
          ]}
          features={[
            <FormattedMessage
              id="fhgfshe3535jgfdjfghda"
              defaultMessage="Screen & cam recording"
            />,
            <FormattedMessage
              id="shreh5632vdfnjjj35k"
              defaultMessage="Screenshots"
            />,
            <FormattedMessage
              id="bvcvcvnbnerreuthk4554"
              defaultMessage="Team Library"
            />,
            <FormattedMessage
              id="fgjfgdjghfd456fhjthj23"
              defaultMessage="Viewer Insights"
            />,
          ]}
        />
        <div className="plan-card-spacer" />
        <SettingsPlanCard
          height="850px"
          planType={intl.formatMessage({
            id: "jgkkleiwiqwio34535df",
            defaultMessage: "business",
          })}
          title={intl.formatMessage({
            id: "fsdaghr35634212bdfykykt",
            defaultMessage: "Business",
          })}
          subTitle={intl.formatMessage({
            id: "bntyui4567cw3sdfg",
            defaultMessage: "Advanced recording & analytics",
          })}
          price={intl.formatMessage({
            id: "asdfad367kthd4656k",
            defaultMessage: "$50",
          })}
          priceSubText={intl.formatMessage({
            id: "jtyu56743fhhje4v",
            defaultMessage: "per month",
          })}
          featuresText={intl.formatMessage({
            id: "8jgktyeiq23fgj",
            defaultMessage: "Everything in Starter, plus",
          })}
          workspaceLimits={[
            <FormattedMessage
              id="7hty553dvbnyt5s"
              defaultMessage="Unlimited videos"
            />,
            <FormattedMessage
              id="ew231ccxzbtyujmfdsf"
              defaultMessage="Unlimited recording length"
            />,
          ]}
          features={[
            <FormattedMessage
              id="hty6784fre32cxq234"
              defaultMessage="Video drawing tool"
            />,
            <FormattedMessage
              id="qwerty456fhzzae4dfwsa"
              defaultMessage="Calls-to-action"
            />,
            <FormattedMessage
              id="htrj355635dghsasdfsfg"
              defaultMessage="Custom branding"
            />,
            <FormattedMessage
              id="jygrjrgfgfh5432bgfs"
              defaultMessage="Engagement Insights"
            />,
          ]}
          onClick={callbacks.onOk}
        />
        <div className="plan-card-spacer" />
        <SettingsPlanCard
          height="850px"
          planType={intl.formatMessage({
            id: "jtu78ew234gjkdfgd",
            defaultMessage: "enterprise",
          })}
          title={intl.formatMessage({
            id: "lkjuo9ut4532dcsw2",
            defaultMessage: "Enterprise",
          })}
          subTitle={intl.formatMessage({
            id: "fdswrewrtet545367ujjfgnbnm",
            defaultMessage: "Advanced admin, security & intergrations",
          })}
          price={intl.formatMessage({
            id: "tyurewq25645gh4",
            defaultMessage: "Let's talk",
          })}
          featuresText={intl.formatMessage({
            id: "gfhtyurqwer34567cv1",
            defaultMessage: "Everything in Business, plus",
          })}
          workspaceLimits={[
            <FormattedMessage
              id="234frypol1nmi89z"
              defaultMessage="Unlimited videos"
            />,
            <FormattedMessage
              id="ertyqas2390plmnb"
              defaultMessage="Unlimited recording length"
            />,
            <FormattedMessage
              id="bvbrtywqaszionbgd20f"
              defaultMessage="Unlimited members"
            />,
          ]}
          features={[
            <FormattedMessage
              id="vbrtyuwer17vaqgjk"
              defaultMessage="SSO (SAML)"
            />,
            <FormattedMessage
              id="mpolk49v27zxw2ty"
              defaultMessage="Private content"
            />,
            <FormattedMessage
              id="qpm5i0o2c0z9jfxsqty"
              defaultMessage="Salesforce integration"
            />,
            <FormattedMessage
              id="hgkhkh4588gfghgdfdjk"
              defaultMessage="SCIM (Coming soon!)"
            />,
          ]}
          onClick={() => navigate("/contact-sales")}
        />

        <SettingsModal
          onOk={() => {
            callbacks.onOk();
            callbacks.sendEvent();
          }}
          onInviteUser={() => {
            callbacks.onOk();
            navigate("/app/klip-rewards");
            callbacks.setPurchasedBusinessPlan("true");
          }}
          onCancel={() => {
            callbacks.onCancel();
          }}
          modalState={states.modalState}
          visible={
            states.modalState === "confirmation" ||
            states.modalState === "success"
          }
          referral={states.referral}
        />
      </div>
    </div>
  );
};
