export const Notification = () => {
  return (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.67833 23.5117C8.67833 24.795 9.71667 25.8333 11 25.8333C12.2833 25.8333 13.3217 24.795 13.3217 23.5117H8.67833ZM11 5.22222C14.22 5.22222 17.2222 8.61333 17.2222 11.8333V18.8333L18.7778 20.7778H3.22222L4.77778 18.8333V11.963C4.77778 8.74296 7.78 5.22222 11 5.22222ZM11 0.75C10.0317 0.75 9.25 1.53167 9.25 2.5V3.865C5.58667 4.65833 2.83333 7.925 2.83333 11.8333V18.8333L0.5 21.1667V22.3333H21.5V21.1667L19.1667 18.8333V11.8333C19.1667 7.925 16.4133 4.65833 12.75 3.865V2.5C12.75 1.53167 11.9683 0.75 11 0.75Z"
        fill="#555555"
      />
    </svg>
  );
};
