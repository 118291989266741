import { useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { LoggedInUser } from "../../state/CoreState";

export default function Logout() {
  const [, setUsername] = useAtom(LoggedInUser);
  let navigate = useNavigate();

  useEffect(() => {
    // Logout on render
    setUsername();
    window.impactToken = undefined;
    navigate("/login");
  }, [setUsername, navigate]);

  return <></>;
}
