import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import KlipVidOne from "../../assets/images/KlipVidOne.png";
import KlipVidThree from "../../assets/images/KlipVidThree.png";
import KlipVidTwo from "../../assets/images/KlipVidTwo.png";
import { LoggedInUser, UserName, WidgetIdent } from "../../state/CoreState";
import { myCustomStorageAtom } from "../../state/StorageAtom";
import { useAuthRequired } from "../../state/useAuthRequired";
import { sendIreEvent } from "../../utils/ireEvent";

export const VideosAtom = myCustomStorageAtom("videos", '""');

export const useDashboard = () => {
  useAuthRequired();
  const [user] = useAtom(LoggedInUser);
  const widgetIdent = useAtomValue(WidgetIdent);
  //@ts-ignore
  const [userName] = useAtom<User>(UserName);
  const intl = useIntl();

  const [videos, setVideos] = useAtom(VideosAtom);

  useEffect(() => {
    if (!user) return;
    const currentVideos = localStorage.getItem(`${user?.email}:videos`) || '""';
    setVideos(JSON.parse(currentVideos));
  }, [user]);

  const randomizedIndex = (arr: string[]): number => {
    return Math.floor(Math.random() * arr.length);
  };

  const storeVideo = (video: object) => {
    let videoArray;

    if (!videos) {
      videoArray = [];

      videoArray.push(video);

      setVideos(JSON.stringify(videoArray));
    } else {
      videoArray = JSON.parse(videos || "[]");

      videoArray.push(video);

      setVideos(JSON.stringify(videoArray));
    }
  };

  const getVideosFromStorage = () => {
    return JSON.parse(videos || "[]");
  };

  const [userVideos, setUserVideos] = useState<object[]>(
    getVideosFromStorage()
  );

  const videoCardImages = [KlipVidOne, KlipVidTwo, KlipVidThree];
  const videoDescriptions = [
    intl.formatMessage({
      id: "asd3d3e3gv43634v63v45",
      defaultMessage: "Market research",
    }),
    intl.formatMessage({
      id: "v34535n7467474745",
      defaultMessage: "Quarterly goals",
    }),
    intl.formatMessage({
      id: "45745v76456v35635dsrfwefewr",
      defaultMessage: "Executive strategy review",
    }),
    intl.formatMessage({
      id: "ewvrwedrw545345v345",
      defaultMessage: "Get started with Klip",
    }),
  ];
  const videoUploadTimes = [
    intl.formatMessage({
      id: "evwrerv5474574573",
      defaultMessage: "Uploaded 1 hour ago",
    }),
    intl.formatMessage({
      id: "va6a46v45646e464667v6",
      defaultMessage: "Uploaded 2 hours ago",
    }),
    intl.formatMessage({
      id: "m8967967b623454b7645674f",
      defaultMessage: "Uploaded 3 hours ago",
    }),
    intl.formatMessage({
      id: "b3456berterb34634",
      defaultMessage: "Uploaded 4 hours ago",
    }),
    intl.formatMessage({
      id: "bertertber674573456345",
      defaultMessage: "Uploaded 5 hours ago",
    }),
  ];

  //Send uploadVideo event to SSQT
  function sendEvent() {
    if (!user) return;

    sendIreEvent({
      user,
      event: {
        key: "uploadVideo",
        fieldName: "timeInHours",
        fieldValue: 0.5,
      },
      actionTrackerId: 40623,
    });

    // window.squatch?.events()?.track(
    //   {
    //     userId: user.shaEmail,
    //     accountId: user.shaEmail,
    //     events: [{ key: "uploadVideo", fields: { timeInHours: 0.5 } }],
    //   },
    //   { jwt: widgetIdent?.jwt || "" }
    // );
  }

  //Upsert the user just incase they haven't been upserted in SSQT before
  //Could cause getting rewarded on event sent to not occur
  useEffect(() => {
    if (user?.email)
      window.squatch?.api && window.squatch?.api()?.upsertUser(widgetIdent);
  }, [user, widgetIdent]);

  const handleClick = () => {
    sendEvent();
    const image = randomizedIndex(videoCardImages);
    const description = randomizedIndex(videoDescriptions);
    const uploadedText = randomizedIndex(videoUploadTimes);
    const newVideo = {
      imageIndex: image,
      descriptionIndex: description,
      uploadTimeIndex: uploadedText,
    };
    setUserVideos(() => [...userVideos, newVideo]);
    storeVideo(newVideo);
  };

  return {
    data: {
      videoCardImages,
      videoDescriptions,
      videoUploadTimes,
      name:
        userName?.firstName && userName?.lastName
          ? `${userName.firstName} ${userName.lastName}`
          : "Robin Lee",
    },
    callbacks: { sendEvent, getVideosFromStorage, handleClick },
  };
};
