import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { User, UserName } from "../../state/CoreState";

const collapseAtom = atom(false);
const breakAtom = atom(false);

export const useNavigation = () => {
  //@ts-ignore
  const [userName] = useAtom<User>(UserName);
  const [canBreak, setCanBreak] = useAtom(breakAtom);
  const [collapsed, setCollapsed] = useAtom(collapseAtom);
  const navigate = useNavigate();

  function onNavigate(path: string) {
    navigate(path);
    canBreak && setCollapsed(true);
  }

  return {
    data: {
      name: userName?.firstName || "Robin",
    },
    states: {
      canBreak,
      collapsed,
    },
    callbacks: {
      setCanBreak,
      setCollapsed,
      onNavigate,
    },
  };
};
