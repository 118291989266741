import { Button } from "antd";
import { useAtomValue } from "jotai";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import { Logo } from "../../../assets/icon/Logo";
import { INSTANT_ACCESS_PROGRAM, LocaleAtom } from "../../../state/CoreState";
import "./Navbar.css";

type NavbarProps = {
  hasButtons?: boolean;
};

export const Navbar = ({ hasButtons }: NavbarProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const locale = useAtomValue(LocaleAtom);

  const hasAutoPopup = !!location.search;

  return (
    <div className="navbar-container">
      <Logo onClick={() => navigate("/")} />
      {hasButtons && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {/* @ts-ignore */}
          <impact-popup
            widget={`p/${INSTANT_ACCESS_PROGRAM}/w/${
              hasAutoPopup ? "friend" : "websiteReferral"
            }Widget`}
            id="link-btn"
            className="squatchpop"
            locale={locale}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.6875 20V19.875V14.25H10.875V21H5.6875C5.13522 21 4.6875 20.5523 4.6875 20ZM13.125 14.25V21H18.3125C18.8648 21 19.3125 20.5523 19.3125 20V19.875V14.25H13.125ZM19.3125 13.125H13.125V8.625H17.0071H19.875H20C20.5523 8.625 21 9.07272 21 9.625V9.75V12V12.125C21 12.6773 20.5523 13.125 20 13.125H19.875H19.3125ZM10.875 13.125H4.6875H4.125H4C3.44772 13.125 3 12.6773 3 12.125V12V9.75V9.625C3 9.07272 3.44772 8.625 4 8.625H4.125H8.22273H10.875V13.125Z"
                fill="var(--primary-color)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 8.625C17.2531 8.55465 18.75 7.04511 18.75 6.04818C18.75 4.72635 18.202 3.67995 17.1886 3.22381C16.2086 2.7827 15.0748 3.02604 14.183 3.67008C13.174 4.39866 12.5737 5.75566 12.0478 6.94434C12.0021 7.04767 11.9569 7.14973 11.9121 7.25C11.8661 7.14425 11.82 7.03701 11.7734 6.9288C11.2426 5.69584 10.6576 4.33678 9.61473 3.61275C8.76508 3.02287 7.69037 2.80202 6.75957 3.2048C5.78553 3.62629 5.25 4.60283 5.25 5.83582C5.25 7.10104 6.61403 8.28955 8.16141 8.625H10.877H13.125H16.5ZM7.44561 4.69459C7.69441 4.58693 8.29433 4.50587 8.55594 4.6875C9.44502 5.30475 10.2844 6.03133 10.877 7.5C9.13621 7.5 8.16141 7.25 8.16141 7.25C8.16141 7.25 6.93302 6.59464 6.93302 5.83582C6.93302 5.0549 7.24006 4.78354 7.44561 4.69459ZM16.6797 4.6875C16.1265 4.22639 15.4794 4.29718 14.9389 4.6875C14.204 5.21823 13.924 5.87036 13.6024 6.61981C13.4821 6.90002 13.356 7.19384 13.1981 7.5C14.0265 7.48214 14.5109 7.48734 15.0627 7.49327C15.3654 7.49653 15.6883 7.5 16.0994 7.5C16.6797 7.5 17.26 6.48582 17.26 5.8125L17.249 5.73292C17.1872 5.27809 17.1614 5.08906 16.6797 4.6875Z"
                fill="var(--primary-color)"
              />
            </svg>
            <p>
              <FormattedMessage
                id="hg656865gfdfsgg32u5bvdfdsfi32j5v252"
                defaultMessage={"Refer a Friend"}
              />
            </p>
            {/* @ts-ignore */}
          </impact-popup>
          <Button
            id="nav-login-btn"
            size="large"
            onClick={() => navigate("/login")}
          >
            <FormattedMessage
              id="8u0g32u5bvi32j5v252"
              defaultMessage={"Login"}
              description={"Button used for logging in from the website"}
            />
          </Button>

          <Button
            id="nav-register-btn"
            type="primary"
            onClick={() => navigate("/register")}
          >
            <FormattedMessage
              id="125oug21o0g3216236"
              defaultMessage={"Sign up"}
              description={
                "Button used for signing into the app from the website"
              }
            />
          </Button>
        </div>
      )}
    </div>
  );
};
