import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { NavigateFunction } from "react-router";

type PromoButtonProps = {
  login: boolean;
  navigate: NavigateFunction;
};

export const PromoButtons = ({ login, navigate }: PromoButtonProps) => {
  return (
    <div
      className="promo-buttons-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {login ? (
        <Button
          size="large"
          style={{ marginRight: "20px" }}
          onClick={() => navigate("/login")}
        >
          <FormattedMessage
            id="8u0g32u5bvi32j5v252"
            defaultMessage={"Login"}
            description={"Button used for logging in from the website"}
          />
        </Button>
      ) : (
        <Button
          size="large"
          style={{ marginRight: "20px" }}
          onClick={() => navigate("/contact-sales")}
        >
          {" "}
          <FormattedMessage
            id="215ouv12oj5vo5b21512"
            defaultMessage={"Contact sales"}
            description={
              "Button used for requesting a demo in from the website"
            }
          />
        </Button>
      )}
      <Button size="large" type="primary" onClick={() => navigate("/register")}>
        <FormattedMessage
          id="125oug21o0g3216236"
          defaultMessage={"Sign up"}
          description={"Button used for signing into the app from the website"}
        />
      </Button>
    </div>
  );
};
