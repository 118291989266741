import { useEffect } from "react";
import { atom, useAtom } from "jotai";
import { REFERRAL_PROGRAM } from "../../state/CoreState";

const referralAtom = atom("");

export function useContactSales() {
  const [referralCode, setReferralCode] = useAtom(referralAtom);
  const redirectUrl = window.location.origin + "/contact-sales-success";

  useEffect(() => {
    window.squatch
      ?.api()
      ?.squatchReferralCookie()
      .then(function (response: any) {
        const code = response?.codes[REFERRAL_PROGRAM];
        if (code) {
          setReferralCode(code);
        }
      });
  }, []);

  return { data: { referralCode, redirectUrl } };
}
