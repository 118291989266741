import { Button } from "antd";
import { ReactEventHandler } from "react";
import { FormattedMessage } from "react-intl";
import { CheckMark } from "../../../assets/icon/CheckMark";
import { Container } from "../../layout/Container";
import "../Settings.css";

type SettingsPlanCardProps = {
  planType: string;
  height: string;
  title: string;
  subTitle: string;
  price: string;
  priceSubText?: string;
  featuresText: string;
  workspaceLimits: any[];
  features: any[];
  onClick?: ReactEventHandler;
};

export const SettingsPlanCard = ({
  planType,
  height,
  title,
  subTitle,
  price,
  priceSubText,
  featuresText,
  workspaceLimits,
  features,
  onClick,
}: SettingsPlanCardProps) => {
  return (
    <Container
      width="90%"
      height={height}
      padding="30px 30px 150px"
      flexDirection="column"
      boxShadow="0px 0px 15px 3px rgba(0, 0, 0, 0.05)"
      alignItems="flex-start"
      backgroundColor={planType === "business" ? "#E7E9FA" : "#fff"}
      border={
        planType === "business" ? "1px solid #4225C4" : "1px solid #e0e0e0"
      }
      borderRadius="8px"
    >
      <Container
        alignItems="flex-start"
        flexDirection="column"
        backgroundColor={planType === "business" ? "#E7E9FA" : "#fff"}
        minHeight="60px"
      >
        <p className="plan-card-title">{title}</p>
        <p className="plan-card-info-text">{subTitle}</p>
      </Container>

      <div className="plan-card-price-container">
        <h1 className="plan-card-price">{price}</h1>
        {priceSubText && <p className="plan-card-info-text">{priceSubText}</p>}
      </div>

      <div className="settings-workspace-list">
        <p className="settings-features-text">
          <FormattedMessage
            id="br56yuiopaqw32vrt"
            defaultMessage="Workspace Limits"
          />
        </p>
        {workspaceLimits.map((text: string, index: number) => (
          <div className="settings-feature-item" key={index}>
            <CheckMark />
            <p className="settings-feature-item-text">{text}</p>
          </div>
        ))}
      </div>

      <div className="settings-workspace-list">
        <p className="settings-features-text">{featuresText}</p>
        {features.map((text: string, index: number) => (
          <div className="settings-feature-item" key={index}>
            <CheckMark />
            <p className="settings-feature-item-text">{text}</p>
          </div>
        ))}
      </div>

      {planType !== "starter" && (
        <Button
          type="primary"
          block
          onClick={onClick}
          style={{ marginTop: "auto" }}
        >
          {planType === "business" ? (
            <FormattedMessage
              id="rtyuiopl76hgbvzaq41"
              defaultMessage="Buy now"
            />
          ) : (
            <FormattedMessage
              id="mnbytrdfhgk874az"
              defaultMessage="Contact sales"
            />
          )}
        </Button>
      )}
    </Container>
  );
};
