import { Dropdown, Menu, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { Avatar } from "../layout/Avatar/Avatar";
import { useVideoCard } from "./useVideoCard";
import "./VideoCard.css";

type VideoCardProps = {
  description: string;
  image: string;
  uploadedText: string;
};

export const VideoCard = ({
  description,
  image,
  uploadedText,
}: VideoCardProps) => {
  const { callbacks } = useVideoCard();

  const dropDownMenu = (
    <Menu onClick={() => callbacks.sendEvent()}>
      <Menu.Item key="1">
        <FormattedMessage
          id="asdasdkaskjdkasjd8q8q738473"
          defaultMessage="Share Video"
        />
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="video-card-container">
      <div className="video-card-header">
        <Avatar dropdown={false} />
        <Space>
          <Dropdown.Button overlay={dropDownMenu} />
        </Space>
      </div>
      <p className="video-description">{description}</p>
      <img src={image} alt="klip-video" className="klip-video-img" />
      <p className="uploaded-time-text">{uploadedText}</p>
    </div>
  );
};
